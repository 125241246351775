<template>
  <div class="terms-conditions-page">
    <vx-card>
      <!-- Card Title -->
      <template slot="title">
        Prime Insights Group LLC Publisher Terms and Conditions
      </template>
      <h1>
        Prime Insights Group LLC Publisher Terms and Conditions
      </h1>

      <hr>

      <!-- Card Content -->
      <div class="card-content">
        <p>
          By registering as a publisher on the Prime Insights Group LLC platform (the "Platform"), you ("Publisher")
          agree to abide by these Terms and Conditions ("Agreement"). Your use of the Platform signifies your acceptance
          of this Agreement and compliance with all applicable laws and regulations.
        </p>
        <hr>

        <h2>1. Eligibility and Account Registration</h2>
        <ul>
          <li>
            <strong>Age Requirement:</strong> You must be at least eighteen (18) years old or a legally established
            corporate entity in good standing.
          </li>
          <li>
            <strong>Accurate Information:</strong> All information provided during registration must be accurate,
            complete, and up-to-date. You are responsible for maintaining the confidentiality of your account
            credentials.
          </li>
          <li>
            <strong>Compliance:</strong> You must have the legal authority to enter into this Agreement and comply with
            all its terms.
          </li>
        </ul>
        <hr>

        <h2>2. Publisher Obligations</h2>
        <ul>
          <li>
            <strong>Content Standards:</strong> Your applications and websites must not contain illegal, defamatory,
            obscene, harmful, or otherwise prohibited content.
          </li>
          <li>
            <strong>Ownership Rights:</strong> You must own or have the rights to all content and properties where you
            integrate the Platform.
          </li>
          <li>
            <strong>User Consent and Privacy:</strong> You are responsible for obtaining all necessary consents from
            your users for data collection and processing. You must provide a clear and comprehensive privacy policy
            that complies with all applicable laws and accurately discloses your data practices, including the
            involvement of third parties like Prime Insights.
          </li>
          <li>
            <strong>No Unauthorized Modifications:</strong> You may not modify, alter, or interfere with the surveys,
            offers, or any content provided through the Platform.
          </li>
          <li>
            <strong>Fraud Prevention:</strong> You must monitor and prevent fraudulent activities. Immediate
            notification to Prime Insights is required if any fraudulent activity is detected.
          </li>
        </ul>
        <hr>

        <h2>3. Services Provided by Prime Insights</h2>
        <ul>
          <li>
            <strong>Access to Platform:</strong> We will provide access to the Platform and Publisher Dashboard,
            allowing you to manage and optimize your monetization strategies.
          </li>
          <li>
            <strong>Survey Distribution:</strong> We will supply surveys from our Advertisers for you to distribute to
            your users.
          </li>
          <li>
            <strong>Integration Tools:</strong> We will provide necessary tools and support for integrating surveys into
            your applications or websites.
          </li>
          <li>
            <strong>Data Tracking:</strong> We will track performance data and make statistics available to you through
            the Dashboard.
          </li>
          <li>
            <strong>Support Services:</strong> We will offer technical support to assist you and your users during
            business hours.
          </li>
        </ul>
        <hr>

        <h2>4. Payment Terms</h2>
        <ul>
          <li>
            <strong>Commission Structure:</strong> You will earn commissions for valid user activities, such as
            completed surveys.
          </li>
          <li>
            <strong>Payment Threshold:</strong> Payments will be issued monthly when your accrued earnings reach a
            minimum of twenty-five dollars ($25) or equivalent in applicable currency. Balances below this threshold
            will be carried over to the next payment period.
          </li>
          <li>
            <strong>Payment Schedule:</strong> Payments will be processed within 60 calendar days after the end of each
            billing period.
          </li>
          <li>
            <strong>Adjustments and Chargebacks:</strong> Advertisers may invalidate a user's participation due to fraud
            or invalid responses. Such invalidations will be deducted from your earnings.
          </li>
          <li>
            <strong>Taxes and Fees:</strong> You are responsible for all applicable taxes. Prime Insights may deduct any
            required taxes or fees related to payment processing.
          </li>
        </ul>
        <hr>

        <h2>5. Compliance and Representations</h2>
        <ul>
          <li>
            <strong>Legal Compliance:</strong> Each party agrees to comply with all applicable laws and regulations with
            respect to the obligations and its performance of the Agreement and the services that are provided under
            this Agreement.
          </li>
          <li>
            <strong>Representations and Warranties:</strong> You represent and warrant that your content does not
            infringe on any intellectual property rights and that you have all necessary rights to distribute it. Prime
            Insights represents and warrants that the Platform and the Advertiser’s survey does not infringe any
            intellectual property rights and that Prime Insights have all necessary rights to distribute it.
          </li>
          <li>
            <strong>Notifications:</strong> Each party must promptly notify the other party of any changes that may
            affect your compliance with this Agreement.
          </li>
        </ul>
        <hr>

        <h2>6. Confidentiality and Data Protection</h2>
        <ul>
          <li>
            <strong>Confidential Information:</strong> “Confidential Information” means any information of a Party
            (“Disclosing Party”) that:
            <ol type="i">
              <li>
                is not, as a whole or in its components, generally known among or readily accessible to the public,
                including Persons that normally deal with the kind of information in question;
              </li>
              <li>
                has actual or potential commercial value because of its confidential or secret nature;
              </li>
              <li>
                Disclosing Party has taken reasonable steps under the circumstances to keep confidential or secret; and
              </li>
              <li>
                is furnished after the Effective Date by or on behalf of the Disclosing Party to the other Party
                (“Receiving Party”) with an identification of its confidential nature (if conveyed verbally, identified
                at the time of disclosure as confidential with a confirmatory writing within three (3) days, and if
                conveyed in writing or other tangible form, prominently marked as “confidential”).
              </li>
            </ol>
            Each Receiving Party agrees to safeguard Disclosing Party’s Confidential Information from disclosure to
            anyone other than as expressly permitted hereby and, at a minimum, to use efforts commensurate with those
            that Receiving Party employs for protecting the confidentiality of its own Confidential Information, but in
            no event less than reasonable care. Receiving Party agrees that it shall not:
            <ol type="i">
              <li>
                use Disclosing Party’s Confidential Information for any purpose other than for the purpose of exercising
                its rights or of performing its obligations under this Agreement; or
              </li>
              <li>
                copy or otherwise reproduce Disclosing Party’s Confidential Information, or disclose, disseminate or
                otherwise communicate in whole or in part Disclosing Party’s Confidential Information to any third
                party, provided that any disclosure of Disclosing Party’s Confidential Information may be made to the
                officers, directors, agents, employees or other representatives of Receiving Party who need to know such
                Confidential Information for the purposes of exercising Receiving Party’s rights or of performing
                Receiving Party’s obligations under this Agreement, who have been informed of the confidential nature of
                Disclosing Party’s Confidential Information and who undertake to treat Disclosing Party’s Confidential
                Information in accordance with the provisions of this Section.
              </li>
            </ol>
            Notwithstanding anything to the contrary herein, the following will not constitute “Confidential
            Information” for the purposes of this Agreement:
            <ol type="i">
              <li>
                Disclosing Party’s information that the Receiving Party can show, by documented and competent evidence,
                was known by it prior to the disclosure thereof by Disclosing Party to it or its representatives;
              </li>
              <li>
                Disclosing Party’s information that is or becomes generally available to the public other than as a
                result of a disclosure directly or indirectly by the Receiving Party or its representatives in breach of
                this Agreement;
              </li>
              <li>
                Disclosing Party’s information that is or becomes available to the Receiving Party on a non-confidential
                basis from a source other than the Disclosing Party or its representatives, provided that such source is
                not known by the Receiving Party to be subject to any prohibition against transmitting the information
                to the Receiving Party;
              </li>
              <li>
                Disclosing Party’s information that the Receiving Party can show, by documented and competent evidence,
                to have been developed independently by the Receiving Party without using the information; or
              </li>
              <li>
                Disclosing Party’s information for which the Disclosing Party has authorized in writing the unrestricted
                disclosure.
              </li>
            </ol>
            Furthermore, Receiving Party shall not be deemed to be in violation of this Section if it discloses
            Confidential Information pursuant to the lawful subpoena or order of a competent judicial, legislative or
            administrative authority, provided that the Receiving Party gives immediate written notification to the
            Disclosing Party of such subpoena or order so as to allow Disclosing Party to seek a protective order or
            other appropriate remedy and provided, further, that in the event that such protective order or other remedy
            is not obtained, the Receiving Party agrees to furnish only that portion of the Confidential Information
            which is legally required and to exercise reasonable efforts to obtain assurances that confidential
            treatment will be accorded to such information.
          </li>
          <li>
            <strong>Data Protection:</strong> Both parties will comply with all applicable data protection laws and
            regulations, including GDPR and CCPA. The parties shall comply with the restrictions and obligations set out
            in the data processing addendum executed by the parties.
          </li>
          <li>
            <strong>User Privacy:</strong> Each party must protect user data and ensure it is collected, processed, and
            stored securely.
          </li>
          <li>
            <strong>Children's Privacy:</strong> If a party collects data from children under 13 years of age, you must
            comply with COPPA and inform the other party accordingly.
          </li>
        </ul>
        <hr>

        <h2>7. Intellectual Property Rights</h2>
        <ul>
          <li>
            <strong>License Grant:</strong> Prime Insights grants you a non-exclusive, non-transferable license to use
            the Platform and related materials solely for the purposes outlined in this Agreement.
          </li>
          <li>
            <strong>Ownership:</strong> All intellectual property rights in the Platform and services remain with Prime
            Insights. You shall not reproduce, distribute, or create derivative works without express written
            permission.
          </li>
        </ul>
        <hr>

        <h2>8. Termination</h2>
        <ul>
          <li>
            <strong>Termination by Either Party:</strong> This Agreement may be terminated by either party with thirty
            (30) days' written notice.
          </li>
          <li>
            <strong>Immediate Termination:</strong> Each party may terminate this Agreement immediately if the other
            party materially breaches the Agreement or engages in fraudulent or unlawful activities with respect to this
            Agreement.
          </li>
          <li>
            <strong>Effect of Termination:</strong> Upon termination, you must cease all use of the Platform, and any
            outstanding payments will be settled according to the terms of this Agreement.
          </li>
        </ul>
        <hr>

        <h2>9. Limitation of Liability</h2>
        <ul>
          <li>
            <strong>Disclaimer:</strong> The Platform and services are provided "as is" without warranties of any kind.
          </li>
          <li>
            <strong>Limitation:</strong> Except with respect to any breach of confidentiality obligations, privacy and
            data protection obligations or intellectual property infringement, each party’s total liability is limited
            to the amount of commissions owed to you for the preceding twelve (12) months.
          </li>
          <li>
            <strong>No Liability for Indirect Damages:</strong> Neither party will be liable for indirect, incidental,
            consequential, or punitive damages.
          </li>
        </ul>
        <hr>

        <h2>10. Indemnification</h2>
        <ul>
          <li>
            <strong>Your Obligations:</strong> Each party agrees to indemnify and hold harmless the other party and its
            affiliates from any claims, damages, or expenses arising out of such party’s breach of this Agreement or
            violation of any laws.
          </li>
          <li>
            <strong>Procedure:</strong> The indemnified party will notify the indemnifying party promptly of any such
            claims and provide reasonable assistance at the indemnifying party’s expense.
          </li>
        </ul>
        <hr>

        <h2>11. General Provisions</h2>
        <ul>
          <li>
            <strong>Entire Agreement:</strong> This Agreement constitutes the entire understanding between the parties
            and supersedes all prior agreements.
          </li>
          <li>
            <strong>Amendments:</strong> Any modifications to this Agreement must be in writing and signed by both
            parties.
          </li>
          <li>
            <strong>Assignment:</strong> Neither party may assign or delegate its rights or obligations under this
            Agreement, either in whole or in part, without the prior written consent of the other party, which consent
            shall not be unreasonably withheld or delayed, except that either party may assign its rights or delegate
            its duties (i) in connection with a sale of all or substantially all of such party’s assets or other form of
            transaction in which there is a change of ownership control of such party (ii) to any affiliated entity of
            such party.
          </li>
          <li>
            <strong>Governing Law:</strong> This Agreement is governed by the laws of the State of Delaware, United
            Sates, without regard to its conflict of law provisions.
          </li>
          <li>
            <strong>Dispute Resolution:</strong> Any disputes will be resolved through binding arbitration under the
            rules of the American Arbitration Association; provided that such arbitration shall be limited to one
            arbitrator agreed upon between the parties and nothing herein shall prevent either party from seeking
            injunctive relief in the case of a data breach or equitable relief from a court of competent jurisdiction.
          </li>
          <li>
            <strong>Notices:</strong> All notices must be in writing and sent to the addresses provided by the parties
            or via email as provided by the parties.
          </li>
          <li>
            <strong>Severability:</strong> If any provision is deemed unenforceable, the remaining terms remain in
            effect.
          </li>
          <li>
            <strong>Waiver:</strong> Failure to enforce any provision does not constitute a waiver of future
            enforcement.
          </li>
          <li>
            <strong>Independent Contractors:</strong> The parties are independent contractors; this Agreement does not
            create a partnership, joint venture, or any employer-employee relationship.
          </li>
        </ul>
        <hr>

        <p class="mb-0">
          By using the Prime Insights Platform or integrating our surveys into your applications or websites, you
          acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
        </p>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  name: 'TermsConditions',
}
</script>

<style scoped>
.terms-conditions-page {
  padding: 30px;
}

.card-content {
  margin-top: 20px;
  line-height: 1.8;
  font-size: 16px;
}

/* Headings */
h2 {
  margin-top: 30px;
  margin-bottom: 15px;
}

/* Paragraphs */
p {
  margin-bottom: 20px;
}

/* Unordered lists */
ul {
  list-style: disc;
  padding-left: 25px;
  margin-bottom: 20px;
}

/* List items for more spacing */
ul li {
  margin-bottom: 10px;
}

/* Ordered lists */
ol {
  list-style-type: lower-alpha;
  padding-left: 25px;
  margin-bottom: 10px;
}

/* Horizontal rules */
hr {
  margin: 30px 0;
}
</style>
